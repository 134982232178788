<template>
    <div style="height: 100%">
        <admin-table title="预约记录" @search="handleSearch" v-model="table" :loading="loading">
            <div slot="buttons">
                <el-button type="primary" size="small" @click="dialogVisible = true">打印列表</el-button>
                <el-button type="primary" size="small" @click="handleExport">导出Excel</el-button>
            </div>
            <el-table-column type="index" width="50" align="center"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :key="idx">
            </el-table-column>
            <el-table-column width="310" align="center" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" :type="scope.row.confirm?'success':'warning'" slot="reference"
                               @click="setConfirm(scope.row)">
                        {{scope.row.confirm?'已确认':'未确认'}}
                    </el-button>
                    <el-button size="mini" @click="handleNotice(scope.$index, scope.row)">通知单</el-button>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                                width="180">
                        <p>确定删除[{{scope.row.name}}]的记录吗？</p>
                        <div>
                            <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">确定
                            </el-button>
                            <el-button size="mini" type="default"
                                       @click="$refs[`popover-${scope.$index}`].doClose()">取消
                            </el-button>
                        </div>
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popover>
                </template>
            </el-table-column>
        </admin-table>
        <el-dialog title="打印列表" :visible.sync="dialogVisible" width="70%">
            <div class="page" id="printMe">
                <el-table :data="table.data" style="width: 100%" stripe>
                    <el-table-column type="index" width="50" align="center"/>
                    <el-table-column label="预约时间" width="150">
                        <template slot-scope="scope">
                            {{scope.row.date}} {{scope.row.time}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="患者姓名" width="100"></el-table-column>
                    <el-table-column prop="phone" label="联系电话" width="110"></el-table-column>
                    <el-table-column prop="doctor" label="手术医生" width="100"></el-table-column>
                    <el-table-column prop="diagnosis" label="初步诊断" width="120"></el-table-column>
                    <el-table-column prop="desc" label="备注" width=""></el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" v-print="'#printMe'">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loading: false,
                dialogVisible: false,
                search: {},
                table: {
                    search: [
                        {
                            type: 'date',
                            key: 'date',
                            value: this.$route.query.date || '',
                            placeholder: '预约日期'
                        },
                        {
                            type: 'input',
                            key: 'name',
                            placeholder: '患者姓名'
                        },
                        {
                            type: 'input',
                            key: 'diagnosis',
                            value: '',
                            placeholder: '诊断名'
                        },
                        {
                            type: 'input',
                            key: 'doctor',
                            value: this.$route.query.doctor || '',
                            placeholder: '医生姓名'
                        }
                    ],
                    columns: [
                        {title: '手术日期', field: 'date', width: '110'},
                        {title: '预约时间', field: 'time', width: '90'},
                        {title: '患者姓名', field: 'name', width: '100'},
                        {title: '联系电话', field: 'phone', width: '120'},
                        {title: '手术医生', field: 'doctor', width: '100'},
                        {title: '初步诊断', field: 'diagnosis', width: ''},
                    ],
                    data: [],
                    total: 0,
                },
            }
        },
        methods: {
            async getData(e) {
                const resp = await this.$http.get('/surgery/appoint/', {params: e})
                this.table.data = resp.data.data.data
                this.table.total = resp.data.data.total
            },
            async setConfirm(row) {
                console.log(row)
                row.confirm = !row.confirm
                const resp = await this.$http.put(`/surgery/appoint/confirm/${row.id}`, row)
                console.log(resp)
                this.getData(this.search)
            },
            async handleDelete(idx, row) {
                this.$refs[`popover-${idx}`].doClose()
                const resp = await this.$http.delete(`/surgery/appoint/${row.id}`)
                if (resp.data.code == 200) this.$message.success("删除成功")
                this.getData(this.search)
            },
            handleEdit(idx, row) {
                this.$router.push(`/main/appoint/form?id=${row.id}`)
            },
            handleNotice(idx, row) {
                this.$router.push(`/main/appoint/notice?id=${row.id}`)
            },
            handleSearch(e) {
                this.search = e
                this.getData(this.search)
            },
            makeQuery(queryObject) {
                const query = Object.entries(queryObject)
                    .reduce((result, entry) => {
                        result.push(entry.join('='))
                        return result
                    }, []).join('&')
                return `?${query}`
            },
            handlePrint() {

            },
            handleExport() {
                let params = this.makeQuery(this.search)
                let url = `/surgery/appoint/export/xlsx${params}`
                console.log(url)
                this.$http.get(url, {
                    responseType: 'arraybuffer',
                    xsrfHeaderName: 'Authorization',
                }).then(res => {
                    const filename = res.headers["content-disposition"]
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', filename.split("filename=")[1])
                    document.body.appendChild(link)
                    link.click()
                }).catch(err => {
                    console.log(err)
                })
            }
        },
        mounted() {
            // var now = new Date()
            // var year = now.getFullYear() // 得到年份
            // var month = now.getMonth() // 得到月份
            // var date = now.getDate() // 得到日期
            // month = month + 1
            // month = month.toString().padStart(2, '0')
            // date = date.toString().padStart(2, '0')
            // var defaultDate = `${year}-${month}-${date}`
            // this.$set(this.form, 'date', defaultDate)
            let params = {
                page: 1,
                pageSize: 20,
            }
            this.table.search.forEach(e => {
                params[e.key] = e.value || ''
            })
            this.getData(params)
        }
    }
</script>
<style lang="scss" scoped>
</style>
